import {MegProgramYear} from '@reasoncorp/kyber-js';

import {megApi} from './apiUtils';
import {County, LocalUnit} from '../types';

// App Wide endpoints
export const findCounties = (isStatePortal: boolean = false): Promise<County[]> => megApi.getWithJsonResponse(
  `/counties?isStatePortal=${isStatePortal}`
);

export const findLocalUnits = (): Promise<LocalUnit[]> => megApi.getWithJsonResponse('/local-units');

export const findLocalUnitsByCountyId = (countyId: number | string): Promise<LocalUnit[]> => megApi.getWithJsonResponse(
  `/local-units/${countyId}`
);

// Program Years
export const findEqualizationYears = (): Promise<MegProgramYear[]> => megApi.getWithJsonResponse(
  `/program-years/equalization`
);

export const findMegaStudyYears = (): Promise<MegProgramYear[]> => megApi.getWithJsonResponse(
  `/program-years/study`
);