import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';

import {MegProgramYear, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import {mega} from '../../types';
import {report4030Api} from '../../api/mega';
import * as messages from '../../messages';
import {megApi} from '../../api';
import {ProgramYearSelect} from '../../components/shared';
import {Report4030Table} from '../../components/mega';

const Report4030AdminDashboard = () => {
  const {year} = useParams() as {year: string};
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false,
    processing: false
  });
  const [selectedEqualizationYear, setSelectedEqualizationYear] = useState<number | string>(0);
  const [studyYears, setStudyYears] = useState<MegProgramYear[]>([]);
  const [report4030Dtos, setReport4030Dtos] = useState<mega.Report4030Dto[]>([]);
  const {showErrorAlert} = useAlerts();
  const navigate = useNavigate();

  useEffect(() => {
    const loadReport4030Dtos = async () => {
      try {
        const studyYears = await megApi.findMegaStudyYears();
        setStudyYears(studyYears.filter(programYear => programYear.year > 2022));
        const selectedYear = year ? year : studyYears.filter((studyYear: MegProgramYear) => studyYear.defaultYear)[0].year;
        setSelectedEqualizationYear(selectedYear);

        if (selectedYear !== 0) {
          const report4030Dtos = await report4030Api.findAllByYear(selectedYear);
          setReport4030Dtos(report4030Dtos);
        }
        setLoadingState({loadError: false, loading: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadReport4030Dtos();
  }, [
    selectedEqualizationYear,
    year,
    showErrorAlert
  ]);

  const handleSelectEqualizationYear = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedEqualizationYear(e.target.value);
    navigate(`/state-portal/report-4030/admin/${e.target.value}`);
  }, [
    navigate
  ]);

  const handleViewClick = useCallback((countyId: number) => {
    navigate(`/state-portal/report-4030/admin/${Number(selectedEqualizationYear)}/${countyId}`);
  }, [
    selectedEqualizationYear,
    navigate
  ]);

  const handleViewStatewidePdfClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.openStatewidePdf(selectedEqualizationYear, showErrorAlert);
    } catch (e) {
      showErrorAlert(messages.VIEW_PDF_FAILURE);
    }

    setLoadingState({...loadingState, processing: false});
  }, [
    loadingState,
    selectedEqualizationYear,
    showErrorAlert
  ]);

  return <Container fluid className="Report4030AdminDashboard">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <Row className="mb-2">
        <Col md="2">
          <Formik initialValues={{studyYear: selectedEqualizationYear}}
                  onSubmit={async () => null}>
            {(_) => (
              <ProgramYearSelect onChange={handleSelectEqualizationYear}
                                 name="studyYear"
                                 years={studyYears}
                                 isMega={true}/>
            )}
          </Formik>
        </Col>
        <Col md="10" className="d-flex justify-content-end">
          <Button color="primary"
                  className="mt-4"
                  style={{height: '38px'}}
                  disabled={loadingState.processing}
                  onClick={() => handleViewStatewidePdfClick()}>
            View Statewide PDF
          </Button>
        </Col>
      </Row>
      <Card>
        <CardHeader>
          4030 Admin Dashboard
        </CardHeader>
        <div className="table-fixed">
          <Report4030Table handleViewClick={handleViewClick}
                           report4030Dtos={report4030Dtos}
                           year={Number(selectedEqualizationYear)}
                           isDashboard={true}/>
        </div>
      </Card>
    </>}
    <ProgressModal isOpen={loadingState.processing}
                   title="Generating Statewide 4030 PDF"
                   content="PDF is being generated. Please do not refresh the page, as this could take a few moments."/>
  </Container>;
};

export default Report4030AdminDashboard;