import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {BreadcrumbsNav, FormikCheckboxGroup, FormikSelect, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import {localUnitReport4017Api} from '../../api/mega';
import * as messages from '../../messages';
import {County, LocalUnit, mega} from '../../types';
import {
  LocalUnitReport4017AdjustmentModifierCard,
  LocalUnitReport4017Table,
  LocalUnitReport4017TableRow,
  LocalUnitReport4017TotalRow
} from '../../components/mega';

const LocalUnitReport4017 = () => {
  const {showErrorAlert} = useAlerts();
  const navigate = useNavigate();
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [county, setCounty] = useState<County | undefined>(undefined);
  const [localUnits, setLocalUnits] = useState<LocalUnit[]>([]);
  const [selectedLocalUnitId, setSelectedLocalUnitId] = useState<number | undefined>(undefined);
  const [localUnitReport4017, setLocalUnitReport4017] = useState<mega.LocalUnitReport4017 | undefined>(undefined);
  const [selectedLocalUnitReport4017Row, setSelectedLocalUnitReport4017Row] = useState<mega.LocalUnitReport4017Row | undefined>(undefined);
  const [twoYearStudy, setTwoYearStudy] = useState(false);

  const breadcrumbs = useMemo(() => [
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ], [county, countyId, year]);

  useEffect(() => {
    const loadLocalUnitReport4017 = async () => {
      try {
        const [counties, localUnitReport4017] = await Promise.all([
          megApi.findCounties(true),
          localUnitReport4017Api.find(countyId, year)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        setCounty(currentCounty);
        setLocalUnitReport4017(localUnitReport4017);

        const oneYear = localUnitReport4017.oneYear;
        setLocalUnits(oneYear.map((localUnitReport4017Row: mega.LocalUnitReport4017Row) => localUnitReport4017Row.localUnit));

        setSelectedLocalUnitReport4017Row(oneYear[0]);
        setSelectedLocalUnitId(oneYear[0].localUnit.id);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadLocalUnitReport4017();
  }, [countyId, showErrorAlert, year, navigate]);

  const handleTwoYearStudyChange = useCallback(async (twoYearStudy: boolean) => {
    setTwoYearStudy(twoYearStudy);
    const localUnitReport4017Rows = twoYearStudy ? localUnitReport4017?.twoYear : localUnitReport4017?.oneYear;
    const selectedLocalUnitReport4017Row = localUnitReport4017Rows?.filter((localUnitReport4017Row: mega.LocalUnitReport4017Row) =>
      localUnitReport4017Row.localUnit.id === selectedLocalUnitId)[0];
    setSelectedLocalUnitReport4017Row(selectedLocalUnitReport4017Row);
  }, [localUnitReport4017, selectedLocalUnitId]);

  const handleLocalUnitChange = useCallback((localUnitId: number) => {
    setSelectedLocalUnitId(localUnitId);
    const localUnitReport4017Rows = twoYearStudy ? localUnitReport4017?.twoYear : localUnitReport4017?.oneYear;
    const localUnit = localUnitReport4017Rows?.filter((localUnitReport4017Row: mega.LocalUnitReport4017Row) =>
      localUnitReport4017Row.localUnit.id === localUnitId)[0];
    setSelectedLocalUnitReport4017Row(localUnit);
  }, [localUnitReport4017, twoYearStudy]);

  const renderLocalUnitOptions = useMemo(() => localUnits.map((localUnit: LocalUnit) => {
    return <option key={localUnit.id} value={localUnit.id}>
      {localUnit.displayNameWithType}
    </option>;
  }), [localUnits]);

  const initialValues = {
    twoYearStudy,
    localUnitId: selectedLocalUnitReport4017Row?.localUnit.id ?? null
  };

  return <Container fluid className="LocalUnitReport4017">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && localUnitReport4017 && selectedLocalUnitReport4017Row && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Formik initialValues={initialValues}
              onSubmit={async () => null}
              enableReinitialize={true}>
        {(_) => <>
          <Row className="d-flex justify-content-end">
            <Col xs="12" sm="6" md="9" lg="10" className="pt-md-4 pt-sm-0 pt-xs-0">
              <FormikCheckboxGroup inline={true}
                                   type="switch"
                                   checkboxes={[
                                     {
                                       name: 'twoYearStudy',
                                       value: 'twoYearStudy',
                                       labelText: 'Prior 2-Year Reappraisal Influence',
                                       onChange: () => handleTwoYearStudyChange(!twoYearStudy)
                                     }
                                   ]}/>
            </Col>
            <Col xs="12" sm="6" md="3" lg="2">
              <FormikSelect name="localUnitId"
                            labelText="Local Unit"
                            onChange={(e) => handleLocalUnitChange(Number(e.target.value))}>
                {renderLocalUnitOptions}
              </FormikSelect>
            </Col>
          </Row>
          <Card className="mb-4">
            <CardHeader>
              4017/4047 Residential Unit Report
            </CardHeader>
            <CardBody>
              <LocalUnitReport4017AdjustmentModifierCard beginYear={Number(year) - 2}
                                                         endYear={Number(year) - 1}
                                                         beforeAdjustment={selectedLocalUnitReport4017Row.adjustmentModifier1BeforeAdjustment}
                                                         afterAdjustment={selectedLocalUnitReport4017Row.adjustmentModifier1AfterAdjustment}
                                                         adjustmentModifier={selectedLocalUnitReport4017Row.adjustmentModifier1}
                                                         reportLineNumbers={[1, 2, 3]}
                                                         isTwoYearStudy={twoYearStudy}
                                                         divideLine1={1}
                                                         divideLine2={2}/>
              <LocalUnitReport4017AdjustmentModifierCard beginYear={Number(year) - 1}
                                                         endYear={Number(year)}
                                                         beforeAdjustment={selectedLocalUnitReport4017Row.adjustmentModifier2BeforeAdjustment}
                                                         afterAdjustment={selectedLocalUnitReport4017Row.adjustmentModifier2AfterAdjustment}
                                                         adjustmentModifier={selectedLocalUnitReport4017Row.adjustmentModifier2}
                                                         reportLineNumbers={[4, 5, 6]}
                                                         isTwoYearStudy={twoYearStudy}
                                                         divideLine1={4}
                                                         divideLine2={5}/>
              <LocalUnitReport4017AdjustmentModifierCard beginYear={Number(year) - 2}
                                                         endYear={Number(year)}
                                                         adjustmentModifier={selectedLocalUnitReport4017Row.adjustmentModifier3}
                                                         reportLineNumbers={[7]}
                                                         isTwoYearStudy={twoYearStudy}
                                                         divideLine1={3}
                                                         divideLine2={6}/>
              <LocalUnitReport4017Table className="mb-4"
                                        headerText="24 Month Sales Study">
                <LocalUnitReport4017TableRow salesPeriod={1}
                                             year={Number(year) - 2}
                                             beginDate={`04/01/${Number(year) - 2}`}
                                             endDate={`09/30/${Number(year) - 2}`}
                                             period={selectedLocalUnitReport4017Row.period1}/>
                <LocalUnitReport4017TableRow salesPeriod={2}
                                             year={Number(year) - 2}
                                             beginDate={`10/01/${Number(year) - 2}`}
                                             endDate={`03/31/${Number(year) - 1}`}
                                             period={selectedLocalUnitReport4017Row.period2}/>
                <LocalUnitReport4017TotalRow headerText="1st and 2nd Periods 12 Month Totals"
                                             totalsRow={selectedLocalUnitReport4017Row.totals1}/>
                <LocalUnitReport4017TableRow salesPeriod={3}
                                             year={Number(year) - 1}
                                             beginDate={`04/01/${Number(year) - 1}`}
                                             endDate={`09/30/${Number(year) - 1}`}
                                             period={selectedLocalUnitReport4017Row.period3}/>
                <LocalUnitReport4017TableRow salesPeriod={4}
                                             year={Number(year) - 1}
                                             beginDate={`10/01/${Number(year) - 1}`}
                                             endDate={`03/31/${year}`}
                                             period={selectedLocalUnitReport4017Row.period4}/>
                <LocalUnitReport4017TotalRow headerText="3rd and 4th Periods 12 Month Totals"
                                             totalsRow={selectedLocalUnitReport4017Row.totals2}/>
                <LocalUnitReport4017TotalRow headerText="1st thru 4th Periods 24 Month Totals"
                                             shouldDisplayRatioColumn={false}
                                             totalsRow={selectedLocalUnitReport4017Row.totals3}/>
                <LocalUnitReport4017TotalRow headerText="24 Month Mean Adjusted Ratio"
                                             isRatioRow={true}
                                             totalsRow={selectedLocalUnitReport4017Row.totals3}/>
              </LocalUnitReport4017Table>

              <LocalUnitReport4017Table headerText="12 Month / Single Year Study">
                <LocalUnitReport4017TableRow salesPeriod={4}
                                             year={Number(year)}
                                             beginDate={`04/01/${year}`}
                                             endDate={`09/30/${year}`}
                                             period={selectedLocalUnitReport4017Row.period4}/>
                <LocalUnitReport4017TableRow salesPeriod={5}
                                             year={Number(year)}
                                             beginDate={`10/01/${year}`}
                                             endDate={`03/31/${Number(year) + 1}`}
                                             period={selectedLocalUnitReport4017Row.period5}/>
                <LocalUnitReport4017TotalRow headerText="4th and 5th Periods 12 Month Totals"
                                             shouldDisplayRatioColumn={false}
                                             totalsRow={selectedLocalUnitReport4017Row.totals4}/>
                <LocalUnitReport4017TotalRow headerText="12 Month Aggregate Adjusted Ratio"
                                             isRatioRow={true}
                                             totalsRow={selectedLocalUnitReport4017Row.totals4}/>
              </LocalUnitReport4017Table>
            </CardBody>
          </Card>
        </>
        }
      </Formik>
    </>}
  </Container>;
};

export default memo(LocalUnitReport4017);