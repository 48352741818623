import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  MegProgramYear,
  ProgressIndicator,
  ProgressModal,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {AppraisalParcelReportModal, MegaCard} from '../../components/mega';
import {CountySelect, ProgramYearSelect} from '../../components/shared';
import {megApi} from '../../api';
import {appraisalParcelReportApi, report4030Api} from '../../api/mega';
import * as messages from '../../messages';
import {County, mega} from '../../types';
import * as permissionUtils from '../../utils/permissionUtils';
import {Report4030Badge} from '../../components/badge';

const MegaPortal = () => {
  const {currentUser, permissions} = useUserContext();
  const {countyId, year} = useParams<{countyId: string, year: string}>();
  const navigate = useNavigate();
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [studyYears, setStudyYears] = useState<MegProgramYear[]>([]);
  const [counties, setCounties] = useState<County[]>([]);
  const [report4030, setReport4030] = useState<mega.Report4030Dto | undefined>(undefined);
  const [selectedCountyId, setSelectedCountyId] = useState<number | string>(0);
  const [selectedEqualizationYear, setSelectedEqualizationYear] = useState<number | string>(0);
  const [selectedCountyName, setSelectedCountyName] = useState<string>('');
  const [isAppraisalParcelReportModalOpen, setIsAppraisalParcelReportModalOpen] = useState(false);

  const isFosAnalystForCounty = useMemo(() => {
    return permissionUtils.isFosAnalystForCounty(currentUser, Number(selectedCountyId));
  }, [
    currentUser,
    selectedCountyId
  ]);

  const isFosSupervisorForCounty = useMemo(() => {
    return permissionUtils.isFosSupervisorForCounty(currentUser, Number(selectedCountyId));
  }, [
    currentUser,
    selectedCountyId
  ]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const [studyYears, counties] = await Promise.all([
          megApi.findMegaStudyYears(),
          megApi.findCounties(true)
        ]);
        setStudyYears(studyYears);
        setCounties(counties);
        const selectedYear = year ? year : studyYears.filter((studyYear: MegProgramYear) => studyYear.defaultYear)[0].year;
        const currentCountyId = countyId ? countyId : counties[0].id;
        setSelectedEqualizationYear(selectedYear);
        setSelectedCountyId(currentCountyId);
        setSelectedCountyName(counties.filter((county: County) => county.id === Number(currentCountyId))?.[0]?.displayName ?? '');

        const report4030 = await report4030Api.find(currentCountyId, selectedYear);
        setReport4030(report4030);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void initialLoad();
  }, [countyId, year, showErrorAlert]);

  const handleSelectEqualizationYear = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedEqualizationYear(e.target.value);
    navigate(`/state-portal/analytics/${e.target.value}/${selectedCountyId}`);
  }, [navigate, selectedCountyId]);

  const handleSelectCounty = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCountyId(e.target.value);
    setSelectedCountyName(counties.filter((county: County) => county.id === Number(e.target.value))?.[0]?.displayName ?? '');
    navigate(`/state-portal/analytics/${selectedEqualizationYear}/${e.target.value}`);
  }, [navigate, selectedEqualizationYear, counties]);

  const handleClickReport = useCallback((report: string) => {
    navigate(`/state-portal/analytics/${selectedEqualizationYear}/${selectedCountyId}/${report}`);
  }, [navigate, selectedCountyId, selectedEqualizationYear]);

  const handlePdfClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.openPdf(selectedCountyId, selectedEqualizationYear, showErrorAlert);
    } catch {
      showErrorAlert(messages.VIEW_PDF_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [selectedCountyId, selectedEqualizationYear, showErrorAlert, loadingState]);

  const handleAppraisalParcelReportClick = useCallback(async (localUnitId: number) => {
    if (localUnitId) {
      setIsAppraisalParcelReportModalOpen(false);
      setLoadingState({...loadingState, processing: true});
      try {
        await appraisalParcelReportApi.download(localUnitId, selectedEqualizationYear);
      } catch {
        showErrorAlert(messages.API_FAILURE, true);
      }

      setLoadingState({...loadingState, processing: false});
    }
  }, [showErrorAlert, loadingState, selectedEqualizationYear]);

  const handle4030ReportClick = useCallback(() => {
    if (report4030?.pdf && Number(selectedEqualizationYear) >= 2023 && !permissions.isAdmin) {
      handleClickReport('report-4030');
    } else if (report4030?.pdf && Number(selectedEqualizationYear) >= 2023 && permissions.isAdmin) {
      navigate(`/state-portal/report-4030/admin/${Number(selectedEqualizationYear)}/${selectedCountyId}`);
    } else if (report4030?.pdf) {
      void handlePdfClick();
    }
  }, [
    handlePdfClick,
    handleClickReport,
    report4030,
    selectedEqualizationYear,
    navigate,
    permissions,
    selectedCountyId
  ]);

  const render4030Badge = useMemo(() => {
    if (report4030 === undefined) {
      return null;
    }

    return <Report4030Badge report4030Dto={report4030}
                            isAdmin={permissions.isAdmin}
                            isAnalyst={isFosAnalystForCounty}
                            isSupervisor={isFosSupervisorForCounty}/>;
  }, [
    report4030,
    permissions,
    isFosSupervisorForCounty,
    isFosAnalystForCounty
  ]);

  return <Container fluid className="MegaPortal">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={[{text: 'Analytics Dashboard', active: true}]}/>
      <Formik initialValues={{studyYear: selectedEqualizationYear, county: selectedCountyId}}
              onSubmit={async () => null}>
        {(_) => (
          <Row className="d-flex justify-content-between">
            <Col xs="12" sm="6" md="3" lg="2">
              <ProgramYearSelect onChange={handleSelectEqualizationYear}
                                 name="studyYear"
                                 years={studyYears}
                                 isMega={true}/>
            </Col>
            <Col xs="12" sm="6" md="3" lg="2">
              <CountySelect onChange={handleSelectCounty}
                            counties={counties}/>
            </Col>
          </Row>
        )}
      </Formik>
      <Row>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Master Sales"
                    description="All Classes Countywide Sale Studies - 2 Year Sales Study and For Residential Monitored Units"
                    onClick={() => handleClickReport('master-sales')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Recap Comparison Report"
                    description="Recaps the CED 4018 and the PSD MEGA and then compares the differences"
                    onClick={() => handleClickReport('recap-comparison-report')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Monitor Study"
                    description="Comparison of County Equalization Department Studies to PSD Field Staff Monitor Unit Studies"
                    onClick={() => handleClickReport('monitor-study')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Personal Property Report"
                    description="Provides early notification of possible defects in a CED's Equalization Report"
                    onClick={() => handleClickReport('personal-property')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="PSD Master Sales Year 1"
                    description="Property Services Division Master Sales 1 Year Overview"
                    onClick={() => handleClickReport('psd-master-sales/year1')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="PSD Master Sales Year 2"
                    description="Property Services Division Master Sales 2 Year Overview"
                    onClick={() => handleClickReport('psd-master-sales/year2')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Preliminary CED Recap"
                    description="Preliminary PSD Recap of the Review and Audit of the CED Equalization Studies"
                    onClick={() => handleClickReport('preliminary-ced-recap')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="CED Recap"
                    description="PSD Recap of the Review and Audit of the CED Equalization Studies"
                    onClick={() => handleClickReport('ced-recap')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Modifiers"
                    description="County Adjustment Modifiers and Equalization Factors"
                    onClick={() => handleClickReport('modifiers')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="4017/4047 County Report"
                    description="All Classes Assessment to Sales Ratio Analysis"
                    onClick={() => handleClickReport('county-report-4017')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="4017/4047 Residential Unit Report"
                    description="Sales Ratio Studies Study"
                    onClick={() => handleClickReport('local-unit-report-4017')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="PSD Sales Sample %"
                    description="CED Unit Parcel Count, Assessed Value, and PSD Field Staff Sales Sample % Recap"
                    onClick={() => handleClickReport('psd-sales-sample')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Statistics"
                    description="Residential Sales Statistics for a 1 year and 2 year Study"
                    onClick={() => handleClickReport('statistics')}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="4030 Report"
                    description="4030 Report"
                    disabled={!report4030?.pdf}
                    renderBadge={Number(selectedEqualizationYear) >= 2023 ? render4030Badge : undefined}
                    buttonText={Number(selectedEqualizationYear) >= 2023 ? 'Open' : 'Download'}
                    onClick={handle4030ReportClick}/>
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
          <MegaCard title="Appraisal Parcel Audit / Recycle Report"
                    disabled={loadingState.processing}
                    description="Report displaying appraisals that have been recycled by jurisdiction"
                    buttonText="Download"
                    onClick={() => setIsAppraisalParcelReportModalOpen(true)}/>
        </Col>
      </Row>
      {selectedEqualizationYear && selectedCountyId &&
        <AppraisalParcelReportModal isOpen={isAppraisalParcelReportModalOpen}
                                    onSubmit={handleAppraisalParcelReportClick}
                                    countyId={Number(selectedCountyId)}
                                    countyName={selectedCountyName}
                                    year={Number(selectedEqualizationYear)}
                                    onCancel={() => setIsAppraisalParcelReportModalOpen(false)}/>}
      <ProgressModal isOpen={loadingState.processing}
                     title="Generating Appraisal Parcel Audit / Recycle Report"
                     content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
    </>}
  </Container>;
};

export default MegaPortal;