import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {BreadcrumbsNav, ConfirmationModal, FormikInput, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {mega} from '../../types';
import {report4030Api} from '../../api/mega';
import * as messages from '../../messages';
import {Report4030BypassModal, Report4030HistoryTable, Report4030Table} from '../../components/mega';
import {report4030OverallCommentsSchema, report4030ReturnSchema} from '../../schemas/mega';
import {CommentModal} from '../../components/shared';

const Report4030Admin = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false,
    processing: false
  });
  const [report4030Dto, setReport4030Dto] = useState<mega.Report4030Dto | undefined>(undefined);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isBypassModalOpen, setIsBypassModalOpen] = useState(false);

  const breadcrumbs = useMemo(() => ([
    {
      text: '4030 Report Admin',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/report-4030/admin/${year}`
    },
    {text: report4030Dto?.county?.displayNameWithType ?? '', active: true}
  ]), [
    year,
    report4030Dto
  ]);

  useEffect(() => {
    const loadReport4030Dto = async () => {
      try {
        const report4030Dto = await report4030Api.find(countyId, year);
        setReport4030Dto(report4030Dto);
        setLoadingState({loadError: false, loading: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadReport4030Dto();
  }, [
    countyId,
    year,
    showErrorAlert
  ]);

  const handleAccept = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.accept(countyId, year);
      const report4030Dto = await report4030Api.find(countyId, year);
      setReport4030Dto(report4030Dto);
      showSuccessAlert(messages.REPORT_ACCEPT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_ACCEPT_FAILURE);
    }

    setIsAcceptModalOpen(false);
    setLoadingState({...loadingState, processing: false});
  }, [
    countyId,
    year,
    showErrorAlert,
    showSuccessAlert,
    loadingState
  ]);

  const handleBypass = useCallback(async (userType: string) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.bypass(countyId, year, userType);
      const report4030Dto = await report4030Api.find(countyId, year);
      setReport4030Dto(report4030Dto);
      showSuccessAlert(messages.SIGNATURE_BYPASS_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.SIGNATURE_BYPASS_FAILURE);
    }

    setIsBypassModalOpen(false);
    setLoadingState({...loadingState, processing: false});
  }, [
    countyId,
    year,
    showErrorAlert,
    showSuccessAlert,
    loadingState
  ]);

  const handleSave = useCallback(async (comments: string, formikHelpers: FormikHelpers<any>) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.save(countyId, year, comments);
      const report4030Dto = await report4030Api.find(countyId, year);
      setReport4030Dto(report4030Dto);
      showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_SAVE_FAILURE);
    }
    formikHelpers.setSubmitting(false);
    setIsAcceptModalOpen(false);
    setLoadingState({...loadingState, processing: false});
  }, [
    countyId,
    year,
    showErrorAlert,
    showSuccessAlert,
    loadingState
  ]);

  const handlePdfClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.openPdf(countyId, year, showErrorAlert);
    } catch {
      showErrorAlert(messages.VIEW_PDF_FAILURE, true);
    }

    setLoadingState({...loadingState, processing: false});
  }, [countyId, year, showErrorAlert, loadingState]);

  const handleReturn = useCallback(async (comment: string) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.returnReport(countyId, year, comment);
      const report4030Dto = await report4030Api.find(countyId, year);
      setReport4030Dto(report4030Dto);
      showSuccessAlert(messages.REPORT_RETURN_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_RETURN_FAILURE);
    }

    setLoadingState({...loadingState, processing: false});
  }, [
    loadingState,
    countyId,
    year,
    showErrorAlert,
    showSuccessAlert
  ]);

  const shouldDisableReturnOrAccept = useMemo(() => {
    return loadingState.processing ||
      report4030Dto?.status !== 'CERTIFIED_BY_BOTH';
  }, [
    report4030Dto,
    loadingState.processing
  ]);

  const shouldDisableBypass = useMemo(() => {
    return loadingState.processing ||
      report4030Dto?.bypassedAnalyst ||
      report4030Dto?.bypassedSupervisor ||
      (report4030Dto?.status !== 'CERTIFIED_BY_SUPERVISOR' && report4030Dto?.status !== 'CERTIFIED_BY_ANALYST');
  }, [
    report4030Dto,
    loadingState.processing
  ]);

  return <Container fluid className="Report4030Admin">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && report4030Dto && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
      <Card className="mt-4">
        <CardHeader>
          4030 Report
        </CardHeader>
        <Report4030Table handleViewClick={handlePdfClick}
                         report4030Dtos={[report4030Dto]}
                         year={Number(year)}
                         isViewButtonDisabled={loadingState.processing}
                         isDashboard={false}/>
      </Card>
      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          <Button color="secondary"
                  disabled={shouldDisableBypass}
                  onClick={() => setIsBypassModalOpen(true)}
                  className="mr-2">
            Bypass
          </Button>
          <Button color="danger"
                  disabled={shouldDisableReturnOrAccept}
                  onClick={() => setIsReturnModalOpen(true)}
                  className="mr-2">
            Return
          </Button>
          <Button color="primary"
                  disabled={shouldDisableReturnOrAccept}
                  onClick={() => setIsAcceptModalOpen(true)}>
            Accept
          </Button>
        </Col>
      </Row>
      <Formik initialValues={{comments: report4030Dto.overallComments ?? ''}}
              validateOnMount={true}
              enableReinitialize={true}
              validationSchema={report4030OverallCommentsSchema}
              onSubmit={({comments}, formikHelpers) => handleSave(comments, formikHelpers)}>
        {(formikProps) => <>
          <Card className="mt-4">
            <CardHeader>
              Overall Comments
            </CardHeader>
            <CardBody>
              <FormikInput name="comments"
                           type="textarea"
                           ariaLabel="Overall Comments"
                           maxLength={1000}/>
            </CardBody>
          </Card>
          <Row className="mt-4">
            <Col className="d-flex justify-content-end">
              <Button color="success"
                      disabled={loadingState.processing}
                      onClick={formikProps.submitForm}>
                Save
              </Button>
            </Col>
          </Row>
        </>}
      </Formik>

      <Report4030HistoryTable items={report4030Dto.history}/>

      <CommentModal onSubmit={({comment}) => handleReturn(comment)}
                    isOpen={isReturnModalOpen}
                    modalTitle="Reason for Return"
                    onToggle={() => setIsReturnModalOpen(!isReturnModalOpen)}
                    confirmButtonText="Return"
                    cancelButtonText="Cancel"
                    maxLength={500}
                    inputType="textarea"
                    schema={report4030ReturnSchema}>
        Provide a reason for returning the 4030 Report for <span className="text-danger">{report4030Dto.county.displayNameWithType}</span>.
      </CommentModal>
      <ConfirmationModal isOpen={isAcceptModalOpen}
                         title="Accept 4030 Report"
                         size="lg"
                         confirmButtonText="Yes"
                         cancelButtonText="No"
                         confirmCallback={handleAccept}
                         cancelCallback={() => setIsAcceptModalOpen(false)}>
        Are you sure you want to accept the 4030 Report for <span className="text-danger">{report4030Dto.county.displayNameWithType}</span>?
      </ConfirmationModal>
      <Report4030BypassModal isOpen={isBypassModalOpen}
                             disableAnalyst={report4030Dto.certifiedByAnalystId !== null}
                             disableSupervisor={report4030Dto.certifiedBySupervisorId !== null}
                             onBypass={handleBypass}
                             onCancel={() => setIsBypassModalOpen(false)}/>
    </>}
  </Container>;
};

export default Report4030Admin;