import {ChangeEvent, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Container, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  MegProgramYear,
  MiSuiteRole,
  ProgressIndicator,
  ssoUtils,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {FilingCabinetCard} from '../components/filingCabinet';
import {CountySelect, FormCard, ProgramYearSelect, StudyCertificationCard} from '../components/shared';
import {countyFormApi, filingCabinetApi, megApi, studyCertificationApi} from '../api';
import * as messages from '../messages';
import {County, CountyFormDto, FileDetails, StudyCertificationType} from '../types';
import {FormType} from '../enum';

const CountyPortal = () => {
  const {countyId, year} = useParams<{countyId: string, year: string}>();
  const {showErrorAlert} = useAlerts();
  const {currentUser} = useUserContext();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [counties, setCounties] = useState<County[]>([]);
  const [forms, setForms] = useState<CountyFormDto[]>([]);
  const [uploadDetails, setUploadDetails] = useState<FileDetails>({numberOfFiles: 0, dueOn: ''});
  const [equalizationYears, setEqualizationYears] = useState<MegProgramYear[]>([]);
  const [selectedCountyId, setSelectedCountyId] = useState<string | number>(0);
  const [selectedEqualizationYear, setSelectedEqualizationYear] = useState<string | number>(0);
  const [studyCertification, setStudyCertification] = useState<StudyCertificationType | undefined>(undefined);

  const isEqualizationDirectorForCounty = useCallback((countyId: number) => {
    return ssoUtils.hasJurisdictionCanonicalIdAndRole(
      currentUser,
      countyId,
      MiSuiteRole.EQUALIZATION_DIRECTOR
    );
  }, [
    currentUser
  ]);

  const showStudyCertificationCard = useMemo(() => {
    return studyCertification && isEqualizationDirectorForCounty(Number(selectedCountyId));
  }, [
    selectedCountyId,
    studyCertification,
    isEqualizationDirectorForCounty
  ]);

  useEffect(() => {
    const loadFormsAndUploadDetails = async () => {
      setLoadingState({loading: true, loadError: false});

      try {
        const [equalizationYears, counties] = await Promise.all([
          megApi.findEqualizationYears(),
          megApi.findCounties()
        ]);
        setEqualizationYears(equalizationYears);
        setCounties(counties);

        const selectedCountyId = countyId ? countyId : counties[0].id;
        const selectedEqualizationYear = year ? year : equalizationYears.filter((equalizationYear: MegProgramYear) => equalizationYear.defaultYear)[0].year;

        setSelectedCountyId(selectedCountyId);
        setSelectedEqualizationYear(selectedEqualizationYear);

        if (selectedEqualizationYear !== 0 && selectedCountyId !== 0) {
          const [forms, countyFileDetails] = await Promise.all([
            countyFormApi.findForms(selectedCountyId, selectedEqualizationYear),
            filingCabinetApi.findDetailsByCountyIdAndYear(selectedCountyId, selectedEqualizationYear)
          ]);
          setForms(forms);
          setUploadDetails(countyFileDetails);

          if (isEqualizationDirectorForCounty(Number(selectedCountyId))) {
            const studyCertification = await studyCertificationApi.findByCountyIdAndYear(
              selectedCountyId,
              selectedEqualizationYear
            );
            setStudyCertification(studyCertification);
          }
        }

        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void loadFormsAndUploadDetails();
  }, [
    countyId,
    year,
    showErrorAlert,
    isEqualizationDirectorForCounty
  ]);

  const setSelectedYear = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    navigate(`/county-portal/${selectedCountyId}/${e.target.value}/`);
  }, [
    navigate,
    selectedCountyId
  ]);

  const setSelectedCounty = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    navigate(`/county-portal/${e.target.value}/${selectedEqualizationYear}/`);
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const handleFormClick = useCallback((countyId: number, formId: number, formLevel?: string) => {
    if (formLevel === 'LOCAL_UNIT') {
      navigate(`/county-portal/${countyId}/${selectedEqualizationYear}/forms/${formId}/local-units`);
    } else {
      navigate(`/county-portal/${countyId}/${selectedEqualizationYear}/forms/${formId}`);
    }
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const handleCountyFilingCabinetClick = useCallback(() => {
    navigate(`/county-portal/${selectedCountyId}/${selectedEqualizationYear}/filing-cabinet`);
  }, [
    navigate,
    selectedCountyId,
    selectedEqualizationYear
  ]);

  const handleStudyCertificationClick = useCallback(() => {
    navigate(`/county-portal/study-certifications/${selectedCountyId}/${selectedEqualizationYear}`);
  }, [
    navigate,
    selectedCountyId,
    selectedEqualizationYear
  ]);

  const initialValues = useMemo(() => ({
    equalizationYear: selectedEqualizationYear,
    county: selectedCountyId
  }), [
    selectedCountyId,
    selectedEqualizationYear
  ]);

  const renderFormCard = useMemo(() => (form: CountyFormDto) => {
    return <FormCard key={form.id}
                     isCountyUser={true}
                     disabled={form.formType === FormType.FORM_4046}
                     onClick={handleFormClick}
                     form={form}/>;
  }, [
    handleFormClick
  ]);

  const shouldShowFilingCabinetCard = useMemo(() => {
    return uploadDetails.dueOn !== '';
  }, [
    uploadDetails
  ]);

  return (
    <Container fluid className="CountyPortal">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError &&
        <>
          <BreadcrumbsNav breadcrumbs={[{text: 'County Dashboard', active: true}]}/>
          <Formik initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={async () => null}
                  validateOnMount={true}>
            {(_) => (
              <Form autoComplete="off" className="mb-0">
                <Row className="d-flex justify-content-between">
                  <Col xs="12" sm="6" md="3" lg="2">
                    <ProgramYearSelect onChange={setSelectedYear}
                                       years={equalizationYears}/>
                  </Col>
                  <Col xs="12" sm="6" md="3" lg="2">
                    <CountySelect onChange={setSelectedCounty}
                                  counties={counties}/>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <Row>
            {forms.map(renderFormCard)}
            {shouldShowFilingCabinetCard && <FilingCabinetCard onClick={handleCountyFilingCabinetClick}
                                                               isLocalUnitPortal={false}
                                                               details={uploadDetails}/>}
            {studyCertification && showStudyCertificationCard &&
              <StudyCertificationCard onClick={handleStudyCertificationClick}
                                      studyCertification={studyCertification}/>}
          </Row>
        </>}
    </Container>
  );
};

export default memo(CountyPortal);
