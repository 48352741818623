import {useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Container, Table} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {BreadcrumbsNav, ProgressIndicator, TabNav, useAlerts, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {psdSalesSampleApi} from '../../api/mega';
import * as messages from '../../messages';
import {formatDecimal, formatInteger, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {mega} from '../../types';
import {PsdSalesSampleReportRow} from '../../types/mega';

const PsdSalesSample = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [psdSalesSampleReport, setPsdSalesSampleReport] = useState<mega.PsdSalesSampleReport>(undefined);
  const {selectedTab} = useTabNav();
  const {showErrorAlert} = useAlerts();
  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: psdSalesSampleReport?.county?.displayNameWithType ?? '', active: true}
  ]), [
    year,
    countyId,
    psdSalesSampleReport
  ]);

  useEffect(() => {
    const loadPsdSalesSample = async () => {
      try {
        const psdSalesSampleReport = await psdSalesSampleApi.find(countyId, year);
        setPsdSalesSampleReport(psdSalesSampleReport);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadPsdSalesSample();
  }, [countyId, year, showErrorAlert]);

  const selectedClassificationData = useMemo(() => {
    return psdSalesSampleReport?.classifications?.[`${selectedTab}`];
  }, [
    psdSalesSampleReport,
    selectedTab
  ]);

  const renderRow = useMemo(() => (item: PsdSalesSampleReportRow) => {
    return <tr className="text-nowrap" key={item.localUnitId.toString()}>
      <td className="text-center font-weight-bold text-primary">
        {item.localUnitId}
      </td>
      <td className="text-primary font-weight-bold">
        {item.localUnitName}
      </td>
      <td className="text-center">
        {formatInteger(item.numberOfParcels)}
      </td>
      <td className="text-center border-left-3">
        {formatInteger(item.numberOfSales4017)}
      </td>
      <td className="text-center">
        {formatInteger(item.salesAmount4017)}
      </td>
      <td className="text-center">
        {formatDecimal(item.salesPercentOfUnitTotal4017, 2, true)}
      </td>
      <td className="text-center border-left-3">
        {formatInteger(item.numberOfSales4047)}
      </td>
      <td className="text-center">
        {formatInteger(item.salesAmount4047)}
      </td>
      <td className="text-center">
        {formatDecimal(item.salesPercentOfUnitTotal4047, 2, true)}
      </td>
      <td className="text-center border-left-3">
        {formatDecimal(item.parcelsPercentOfTotalClass, 2, true)}
      </td>
      <td className="bg-light-teal text-center border-left-3">
        {formatInteger(item.totalAssessedValue)}
      </td>
      <td className="bg-light-teal text-center border-left-3">
        {formatInteger(item.assessedValueOfSales4017)}
      </td>
      <td className="bg-light-teal text-center">
        {formatDecimal(item.assessedValuePercentOfUnitTotal4017, 2, true)}
      </td>
      <td className="bg-light-teal text-center border-left-3">
        {formatInteger(item.assessedValueOfSales4047)}
      </td>
      <td className="bg-light-teal text-center">
        {formatDecimal(item.assessedValuePercentOfUnitTotal4047, 2, true)}
      </td>
      <td className="bg-light-teal text-center border-left-3">
        {formatDecimal(item.assessedValuePercentOfTotalClass, 2, true)}
      </td>
      <td className="bg-light-teal text-center">
        {formatInteger(item.averageTrueCashValueParcelInClass)}
      </td>
      <td className="bg-light-teal text-center">
        {formatInteger(item.averageTrueCashValueParcelThatSold)}
      </td>
    </tr>;
  }, []);

  return <Container fluid className="PsdSalesSampleReport">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && selectedClassificationData && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card>
        <CardHeader>
          PSD Sales Sample %
        </CardHeader>
        <CardHeader className="nav-tabs-header">
          <TabNav/>
        </CardHeader>
        <div className="table-fixed SplitTable">
          <div className="table-responsive" tabIndex={0}>
            <Table bordered>
              <thead className="bg-light text-center">
                <tr className="text-nowrap">
                  <th colSpan={10} className="bg-light text-primary">
                    Total # of Sales and Sale Amounts by Unit and the Respective Sample Percentages of the # of Parcels Sold for the L-4017 and L4047
                  </th>
                  <th colSpan={8} className="bg-light-teal border-left-3 text-primary">
                    Total Amount of Assessed Value for Unit and the Total Assessed Value of Sales by Unit and the Respective Sample Percentages for the L-4017 and L-4047
                  </th>
                </tr>
                <tr className="bg-light text-primary text-center font-weight-bold align-middle position-top-50">
                  <th colSpan={3} className="bg-light text-primary"/>
                  <th colSpan={3} className="bg-light border-left-3 text-primary">
                    L-4017 Sales
                  </th>
                  <th colSpan={3} className="bg-light border-left-3 text-primary">
                    L-4047 Sales
                  </th>
                  <th className="border-left-3 bg-light text-primary"/>
                  <th className="border-left-3 bg-light-teal text-primary"/>
                  <th colSpan={2} className="border-left-3 bg-light-teal text-primary">
                    L-4017 Sales
                  </th>
                  <th colSpan={2} className="border-left-3 bg-light-teal text-primary"
                  >L-4047 Sales
                  </th>
                  <th className="border-left-3 bg-light-teal text-primary"/>
                  <th colSpan={2} className="bg-light-teal text-primary">
                    Average TCV of
                  </th>
                </tr>
                <tr className="bg-light text-primary font-weight-bold position-top-100">
                  <th className="align-middle bg-light text-primary">
                    Unit #
                  </th>
                  <th className="align-middle bg-light text-primary text-left">
                    Unit
                  </th>
                  <th className="align-middle bg-light text-primary">
                    Parcels
                  </th>
                  <th className="align-middle bg-light text-primary border-left-3">
                    # of
                  </th>
                  <th className="align-middle bg-light text-primary">
                    Sales Amount
                  </th>
                  <th className="align-middle bg-light text-primary">
                    % of Unit Total
                  </th>
                  <th className="align-middle bg-light text-primary border-left-3">
                    # of
                  </th>
                  <th className="align-middle bg-light text-primary">
                    Sales Amount
                  </th>
                  <th className="align-middle bg-light text-primary">
                    % of Unit Total
                  </th>
                  <th className="align-middle bg-light text-primary border-left-3">
                    Unit # Parcels % of Total Class
                  </th>
                  <th className="text-primary bg-light-teal align-middle border-left-3">
                    Total Assessed Value
                  </th>
                  <th className="text-primary bg-light-teal align-middle border-left-3">
                    Assessed Value of Sales
                  </th>
                  <th className="align-middle bg-light-teal text-primary">
                    % of Unit Total
                  </th>
                  <th className="text-primary align-middle border-left-3 bg-light-teal">
                    Assessed Value of Sales
                  </th>
                  <th className="align-middle bg-light-teal text-primary">
                    % of Unit Total
                  </th>
                  <th className="text-primary align-middle border-left-3 bg-light-teal">
                    Unit Assessed Value % of Total Class
                  </th>
                  <th className="align-middle bg-light-teal text-primary">
                    Parcel in Class
                  </th>
                  <th className="align-middle bg-light-teal text-primary">
                    Parcel that Sold
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedClassificationData.localUnits.map(renderRow)}
              </tbody>
              <tfoot className="bg-light">
                <tr className="text-nowrap font-weight-bold text-center text-dark" key="totals">
                  <td className="bg-light">Totals</td>
                  <td className="bg-light">
                    {formatInteger(selectedClassificationData.unitsWithParcelsInClass)}
                  </td>
                  <td className="bg-light">
                    {formatInteger(selectedClassificationData.totals.numberOfParcels)}
                  </td>
                  <td className="bg-light border-left-3">
                    {formatInteger(selectedClassificationData.totals.numberOfSales4017)}
                  </td>
                  <td className="bg-light">
                    {formatInteger(selectedClassificationData.totals.salesAmount4017)}
                  </td>
                  <td className="bg-light">
                    {formatDecimal(selectedClassificationData.totals.salesPercentOfUnitTotal4017, 2, true)}
                  </td>
                  <td className="bg-light border-left-3">
                    {formatInteger(selectedClassificationData.totals.numberOfSales4047)}
                  </td>
                  <td className="bg-light">
                    {formatInteger(selectedClassificationData.totals.salesAmount4047)}
                  </td>
                  <td className="bg-light">
                    {formatDecimal(selectedClassificationData.totals.salesPercentOfUnitTotal4047, 2, true)}
                  </td>
                  <td className="bg-light border-left-3">
                    {formatDecimal(selectedClassificationData.totals.parcelsPercentOfTotalClass, 0, true)}
                  </td>
                  <td className="border-left-3 bg-light-teal">
                    {formatInteger(selectedClassificationData.totals.totalAssessedValue)}
                  </td>
                  <td className="border-left-3 bg-light-teal">
                    {formatInteger(selectedClassificationData.totals.assessedValueOfSales4017)}
                  </td>
                  <td className="bg-light-teal">
                    {formatDecimal(selectedClassificationData.totals.assessedValuePercentOfUnitTotal4017, 2, true)}
                  </td>
                  <td className="border-left-3 bg-light-teal">
                    {formatInteger(selectedClassificationData.totals.assessedValueOfSales4047)}
                  </td>
                  <td className="bg-light-teal">
                    {formatDecimal(selectedClassificationData.totals.assessedValuePercentOfUnitTotal4047, 2, true)}
                  </td>
                  <td className="border-left-3 bg-light-teal">
                    {formatDecimal(selectedClassificationData.totals.assessedValuePercentOfTotalClass, 0, true)}
                  </td>
                  <td className="bg-light-teal">
                    {formatInteger(selectedClassificationData.totals.averageTrueCashValueParcelInClass)}
                  </td>
                  <td className="bg-light-teal">
                    {formatInteger(selectedClassificationData.totals.averageTrueCashValueParcelThatSold)}
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </Card>
    </>}
  </Container>;
};

export default withTabNav(PsdSalesSample, {tabs: PROPERTY_CLASSIFICATION_TABS});